import React from 'react'
import { Link } from 'gatsby'

export default function Logo() {
  return (
    <Link to="/">
      <svg viewBox="512.7 281.3 433.9 83.6" className="logo">
        <defs>
          <clipPath id="a">
            <path d="M512.7 281.3h433.9v83.6H512.7z" />
          </clipPath>
        </defs>
        <g clipPath="url(#a)">
          <path
            d="M512.7 281.3h434v83.5h-434v-83.5zm32.3 36.1v11.2q0 3 2 4.4 1.9 1.6 5 1.6t4.5-1q1.4-1 2.3-2.9 1-1.9 2.5-1.9h15.2q.6 0 1 .5.5.5.5 1 0 6.6-6.2 12-3 2.6-8.2 4.3-5 1.6-12 1.6t-12.6-2.1q-5.7-2.1-9.3-7-3.6-4.9-3.6-12V319q0-7 3.6-12 6.7-9 22.5-9 6.3 0 11.4 1.6 5.2 1.6 8.2 4.3 6.2 5.4 6.2 11.9 0 .6-.5 1-.4.5-1 .5h-15.2q-1.5 0-2.5-1.9-.9-1.9-2.3-2.8-1.4-1-4.5-1t-5 1.5q-2 1.6-2 4.4zm78.1-18.9H636q.7 0 1.3.6.5.6.5 1.3v45.2q0 .8-.5 1.4-.6.5-1.3.5h-12.7q-.8 0-1.3-.5-.6-.6-.6-1.4V324l-5.1 10.1q-.4.9-1.3 1.3-.8.5-1.7.5h-4.7q-1.2 0-2-.7l-1-1-5-10.2v21.7q0 .8-.6 1.4-.6.5-1.4.5H586q-.8 0-1.4-.5-.5-.6-.5-1.4v-45.2q0-.7.5-1.3.6-.6 1.4-.6h12.8q2 0 3 2l9.2 16.7 9.1-16.8q1-1.9 3-1.9zm69.6 14.4H680v20.3h12.7q.7 0 1.3.5.5.6.5 1.4v10.5q0 .8-.5 1.4-.6.5-1.3.5h-44.4q-.8 0-1.4-.5-.5-.6-.5-1.4v-10.5q0-.8.5-1.4.6-.5 1.4-.5H661v-20.3h-12.8q-.8 0-1.4-.6-.5-.5-.5-1.3v-10.6q0-.7.5-1.3.6-.6 1.4-.6h44.4q.7 0 1.3.6.5.6.5 1.3V311q0 .8-.5 1.3-.6.6-1.3.6zm30.8 4.5v11.2q0 3 2 4.4 1.9 1.6 5 1.6t4.5-1q1.4-1 2.3-2.9 1-1.9 2.5-1.9H755q.6 0 1 .5.5.5.5 1 0 6.6-6.2 12-3 2.6-8.2 4.3-5 1.6-12 1.6t-12.6-2.1q-5.7-2.1-9.3-7-3.6-4.9-3.6-12V319q0-7 3.6-12 6.7-9 22.5-9 6.3 0 11.4 1.6 5.2 1.6 8.2 4.3 6.2 5.4 6.2 11.9 0 .6-.5 1-.4.5-1 .5h-15.2q-1.5 0-2.5-1.9-.9-1.9-2.3-2.8-1.4-1-4.5-1t-5 1.5q-2 1.6-2 4.4zm74.9-18.9h14.4q.7 0 1.3.6.6.6.6 1.3v45.2q0 .8-.6 1.4-.6.5-1.3.5h-14.4q-.8 0-1.4-.5-.5-.6-.5-1.4v-14.9h-14v15q0 .7-.6 1.3-.6.5-1.3.5h-14.4q-.8 0-1.4-.5-.5-.5-.5-1.4v-45.2q0-.7.5-1.3.6-.6 1.4-.6h14.4q.7 0 1.3.6.6.6.6 1.3v14.4h14v-14.4q0-.7.5-1.3.6-.6 1.4-.6zm45.3 31v4.4h28q.7 0 1.3.5.5.6.5 1.4v9.8q0 .8-.5 1.4-.6.5-1.4.5h-44.2q-.8 0-1.3-.5-.6-.6-.6-1.4v-45.2q0-.7.6-1.3.5-.6 1.3-.6h43.5q.8 0 1.4.6.5.6.5 1.3v9.9q0 .8-.5 1.3-.6.6-1.4.6h-27.2v4.4h23.4q.8 0 1.3.6.6.5.6 1.3v9q0 .9-.6 1.4-.5.6-1.3.6h-23.4zm63.7-29v32.7h23.9q.8 0 1.3.5.6.6.6 1.4v10.5q0 .8-.6 1.4-.5.5-1.3.5h-40.9q-.8 0-1.3-.5-.6-.6-.6-1.4v-45.2q0-.7.6-1.3.5-.6 1.3-.6h15.1q.8 0 1.4.6.5.6.5 1.3z"
            fillRule="evenodd"
            fill="#F70052"
            fillOpacity=".8"
          />
        </g>
      </svg>
    </Link>
  )
}
